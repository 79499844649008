<template>
  <span class="badge" :class="cssCLass">{{text}}</span>
</template>
<script>
export default {
  name: 'AssistantStatus',
  props: ['status'],
  computed: {
    cssCLass() {
      return {
        Active: 'badge-success',
        Blocked: 'badge-warning'
      }[this.status]
    },
    text() {
      return {
        Active: 'ปกติ',
        Blocked: 'ระงับใช้งาน'
      }[this.status]
    }
  }
}
</script>

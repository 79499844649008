<template>
  <b-modal
    ref="unblock-assistant"
    no-close-on-backdrop
    hide-footer
    title="ยกเลิกระงับการใช้งาน"
    ok-variant="success"
    ok-title="ยกเลิกระงับการใช้งาน"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ชื่อผู้ใช้"
      >
        <b-form-input
          v-model="input.username"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>
      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'ตกลง'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import ManageAccountService from "@/services/ManageAccountService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'UnBlockAssistantModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        _id: null,
        username: null,
        blockNote: null
      }
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      input: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.needReload = false
      this.input = {
        _id: null,
        username: null,
        blockNote: null
      }
    },
    showModal() {
      this.$refs['unblock-assistant'].show()
    },
    hideModal() {
      this.$refs['unblock-assistant'].hide()
    },
    handleSubmit() {
      const self = this
      this.isProcess = true
      ManageAccountService.unBlockAssistant(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'ยกเลิกระงับการใช้งานเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e.message || 'ยกเลิกระงับการใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>

<template>
  <b-modal
    ref="assistant-permission-modal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    size="xl"
    @show="setData"
    class="position-relative"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <Permission :input="input" />
      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'ตกลง'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Permission from './Permission'
import AssistantService from '@/services/AssistantService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'PermissionModal',
  components: {
    Permission
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      input: {
        permission: {
          BetReport: 'r',
          WaitReport: 'r',
          OverallReport: 'r',
          WinLose: 'r',
          WinLose2: 'r',
          MemberReport: 'r',
          TicketReport: 'w',
          LimitNumbers: 'r',

          WinLoseReport: 'r',
          MarketWinLose: 'r',
          MemberTypeWinLose: 'r',
          WonTickets: 'r',
          Results: 'r',

          RoundSetting: 'r',
          Payrate: 'w',
          RoundReceive: 'w',
          MarketReceive: 'w',
          SettingHistory: 'r',

          Finance: 'r',
          FinanceReport: 'r',
          FinanceMember: 'w',
          AccountLog: 'r',

          ManageAccount: 'r',
          CreateMember: 'w',
          CreateAgent: 'w',
          MemberSetting: 'w',
          BlockMember: 'w',
          NewMemberSetting: 'w',

          AccountSetting: 'r',
          MemberMarket: 'w',
          MemberSharing: 'w',
          MemberPayout: 'w',
          MemberBetLimit: 'w',
          MemberLottoSet: 'w'
        }
      }
    }
  },
  computed: {
    title() {
      return `ตั้งค่าสิทธิการใช้งาน :: ${this.username}`
    },
    username() {
      return this.data?.username
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
  },
  methods: {
    setData() {
      const permission = JSON.parse(JSON.stringify(this.data.permission))
      this.input = {
        userIds: [this.data._id],
        permission: {
          ...this.input.permission,
          ...permission
        }
      }
    },
    handleSubmit() {
      this.isProcess = true
      AssistantService.setPermissions(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกตั้งค่าเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e.emessage || 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    showModal() {
      this.$refs['assistant-permission-modal'].show()
    },
    hideModal() {
      this.$refs['assistant-permission-modal'].hide()
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
